import {useState, useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';

import type {I18NOptions} from '@/types';
import {twMerge} from '@/stylesheets/twMerge';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {checkImage, fetchReviewerName} from '@/components/blog/utils';
import RenderImage from '@/components/shared/Image/Image';
import {BlogAuthorName} from '@/components/pages/blog/BlogAuthor/BlogAuthorName';
import type {ArticleMetafield, ArticleAuthor} from '@/components/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {getMetafieldAuthors} from '@/pages/shopify.com/($locale)/enterprise/blog/utils/utils';

import type {BlogSocialLink} from '../../../blog/BlogArticleContent/BlogSocialIcons/BlogSocialIcons';
import BlogSocialIcons from '../../../blog/BlogArticleContent/BlogSocialIcons/BlogSocialIcons';

import {
  articleAuthorStyles,
  articleAuthorTimeStyles,
  sharingSectionLinkStyles,
  sharingSectionTitleStyles,
} from './style';

interface BlogAuthorAndSharingSectionProps {
  articleTitle: string;
  author?: ArticleAuthor;
  authorAvatarUrl?: string;
  modifiedAt: string;
  className?: string;
  socialLinksFn?: (opts: I18NOptions) => BlogSocialLink[];
  enBlogPath: string;
  blogPageType?: BLOG_PAGE_TYPE;
  metafields?: ArticleMetafield[];
  isDisabledAuthor?: boolean;
}

const defaultSocialLinks = (options: I18NOptions): BlogSocialLink[] => {
  const {t} = options;
  const socials = ['facebook', 'twitter', 'linkedin'];
  const socialLinks = socials.map((name: string): BlogSocialLink => {
    return {
      name,
      text: t(`global:social.${name}.text`),
    };
  });

  return socialLinks;
};

export const BlogAuthorAndSharingSection = ({
  className,
  articleTitle,
  author,
  authorAvatarUrl,
  modifiedAt,
  socialLinksFn = defaultSocialLinks,
  enBlogPath,
  blogPageType,
  metafields,
}: BlogAuthorAndSharingSectionProps) => {
  const [isImageExist, setIsImageExist] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string | null>();
  const isEnterpriseBlog = blogPageType === BLOG_PAGE_TYPE.ENTERPRISE;

  const translationNamespace = `pages${enBlogPath || '/'}$article/content`;
  const {t} = useSharedTranslations(translationNamespace);

  const enterpriseAuthors = getMetafieldAuthors(metafields || [], author?.name);
  const reviewerName = metafields && fetchReviewerName(metafields);

  useEffect(() => {
    setImagePath(authorAvatarUrl);
  }, [authorAvatarUrl]);

  useEffect(() => {
    imagePath && checkImage(imagePath, setIsImageExist);
  }, [imagePath]);

  const authorData =
    isEnterpriseBlog && enterpriseAuthors
      ? enterpriseAuthors
      : [
          {
            name: author?.name,
            prefix: isEmpty(reviewerName) ? '' : 'authorSection.written',
            isDisabledAuthor: author?.isDisabledAuthor,
          },
          {
            name: reviewerName,
            prefix: isEmpty(reviewerName) ? '' : 'authorSection.reviewed',
          },
        ];

  return (
    <div
      className={twMerge(
        'border-t tablet-xl:border-[#d4d4d8] my-16 py-6 flex tablet-xl:flex-row justify-between flex-col',
        className,
      )}
    >
      <div className="flex items-center tablet-xl:justify-start tablet-xl:flex-row flex-row-reverse justify-between">
        {isImageExist && imagePath && (
          <RenderImage
            className="rounded-full max-h-[68px] tablet-xl:mr-4"
            srcSet={imagePath}
            alt={`${author?.name}-avatar`}
            loading="lazy"
          />
        )}
        <div className="self-center flex flex-col">
          <BlogAuthorName
            blogPageType={blogPageType}
            enBlogPath={enBlogPath}
            authorData={authorData}
            ns={translationNamespace}
            className={articleAuthorStyles({
              blogPageType,
            })}
          />
          <div className={articleAuthorTimeStyles({blogPageType})}>
            {`${t('authorSection.updated')} ${modifiedAt}`}
          </div>
        </div>
      </div>
      <div className="xs:border-b tablet-xl:border-0 pb-4">
        <div className={sharingSectionTitleStyles({blogPageType})}>
          {t('authorSection.headingHtml')}
        </div>
        <BlogSocialIcons
          socialLinksFn={socialLinksFn}
          listClass="flex gap-4 mt-4"
          linkClass={sharingSectionLinkStyles({blogPageType})}
          articleTitle={articleTitle}
          blogPageType={blogPageType}
        />
      </div>
    </div>
  );
};
