import {useEffect, useRef} from 'react';

// Hook that prevents an additional click and sends data to DUX
export const useDuxClickUnsubscribe = ({
  componentSelector,
  articleContentHtml,
}: {
  componentSelector: string;
  articleContentHtml: string;
}) => {
  const isReportToDux = useRef(false);

  useEffect(() => {
    if (isReportToDux.current) return;

    const handleExpand = () => {
      if (!isReportToDux.current) {
        const componentName = document.querySelector(componentSelector);
        isReportToDux.current = true;
        componentName?.removeAttribute('data-click-enabled');
      }
    };

    if (articleContentHtml && componentSelector) {
      const componentName = document.querySelector(componentSelector);

      if (componentName) {
        document?.addEventListener('click', handleExpand);
      }
    }

    return () => document.removeEventListener('click', handleExpand);
  }, [articleContentHtml, componentSelector]);
};
