import type {ReactNode} from 'react';

import AccordionItem from './AccordionItem';

interface AccordionProps {
  accordionItems: {
    buttonText: ReactNode;
    buttonTag?: keyof JSX.IntrinsicElements;
    body: ReactNode | string;
  }[];
  id: string;
  className: string;
  listAttributes?: any;
  listItemAttributes?: any;
  listStyles?: any;
  toggleWrapperStyles?: string;
  noAnimation?: boolean;
  startOpen?: boolean;
  toggleIcons?: {
    expand: ReactNode;
    collapse: ReactNode;
  };
  toggleWrapperClassName?: string;
  toggleClassName?: string;
}

/**
 * @deprecated Use `base/modules/Accordion` instead
 */
export default function Accordion({
  accordionItems,
  id,
  className,
  listAttributes,
  listItemAttributes,
  listStyles,
  toggleWrapperStyles,
  noAnimation = false,
  startOpen = true,
  toggleIcons,
  toggleWrapperClassName,
  toggleClassName,
}: AccordionProps) {
  if (accordionItems.length === 0) {
    return null;
  }

  return (
    <ul className={className} id={id} {...listAttributes}>
      {accordionItems.map(
        (
          accordionItem: {
            buttonText: ReactNode;
            buttonTag?: keyof JSX.IntrinsicElements;
            body: ReactNode;
          },
          index: number,
        ) => (
          <AccordionItem
            buttonId={`${id}-btn-${index}`}
            buttonText={accordionItem.buttonText}
            buttonTag={accordionItem.buttonTag}
            key={`${id}-${index}`}
            listItemAttributes={listItemAttributes}
            listStyles={listStyles}
            noAnimation={noAnimation}
            startOpen={startOpen}
            toggleIcons={toggleIcons}
            toggleId={`${id}-${index}`}
            toggleWrapperStyles={toggleWrapperStyles}
            toggleWrapperClassName={toggleWrapperClassName}
            toggleClassName={toggleClassName}
          >
            {accordionItem.body}
          </AccordionItem>
        ),
      )}
    </ul>
  );
}
