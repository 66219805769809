import {cva, type VariantProps} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {twMerge} from '@/stylesheets/twMerge';

export const wrapperStyles = cva('font-aktivgroteskextended', {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: 'font-faktum',
      [BLOG_PAGE_TYPE.ENGINEERING]: 'text-[#E7ECFB]',
    },
  },
});

export const titleStyles = cva('text-base uppercase mb-6', {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: 'font-faktum',
      [BLOG_PAGE_TYPE.ENGINEERING]: 'font-medium',
    },
  },
});

export const articleItemStyles = cva(
  'mb-4 pl-2 border-l border-[#CAD4D7] inline-block',
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: 'font-medium font-faktum',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'border-engineering-highlight',
      },
    },
  },
);

const articleTagVariants = cva(
  'uppercase text-xs mb-2 block pt-2 font-bold [&_a]:no-underline [&_a]:hover:text-link-light-hover',
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: 'font-normal font-faktum',
        [BLOG_PAGE_TYPE.ENGINEERING]: '[&_a]:hover:text-inherit',
      },
    },
  },
);

export const articleTagStyles = (
  variants: VariantProps<typeof articleTagVariants>,
) => twMerge(articleTagVariants(variants));

export const articleTitleStyles = cva(
  'text-base block hover:underline hover:underline-offset-4 font-normal',
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: 'font-medium font-faktum',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-text',
      },
    },
  },
);
