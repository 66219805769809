import {useCallback, useEffect} from 'react';

import {BLOG_ARTICLE_ID} from '@/components/blog/constants';
import type {ShortcodeTransformer} from '@/components/blog/BlogArticleContent/BlogArticleRenderer/types';

export const blogTruncatedSectionTransformer: ShortcodeTransformer = {
  selector: '.truncated-text',
  transformation: (node: Element) => {
    return {
      component: <BlogTruncatedSection node={node} />,
      node,
    };
  },
};

const BlogTruncatedSection = ({node}: {node: Element}): null => {
  const articleContent = document.getElementById(BLOG_ARTICLE_ID);
  const expandButton = node.querySelector('.truncated-text__toggle');

  const handleExpand = useCallback(() => {
    articleContent?.classList.remove(
      '[&_.truncated-text]:max-h-[300px]',
      '[&_.truncated-text::before]:content-[""]',
    );

    expandButton?.classList.add('hidden');
  }, [articleContent?.classList, expandButton?.classList]);

  useEffect(() => {
    expandButton?.addEventListener('click', handleExpand);

    return () => expandButton?.removeEventListener('click', handleExpand);
  }, [expandButton, handleExpand]);

  return null;
};
