import {cx, cva} from 'class-variance-authority';

export const inputWrapperStyles = cx(
  '[&_input]:rounded-full [&_input]:border-shade-3 [&_input]:mb-1 [&_input]:pl-4 [&_input]:border-solid [&_input]:border',
  '[&_input]:border-shade-30 [&_input]:text-xs tablet-xl:[&_input]:text-base [&_div]:text-xs tablet-xl:[&_div]:text-base',
  '[&_button]:bg-black [&_button]:text-white [&_button]:h-14 [&_button]:text-lg [&_button]:text-lg',
  '[&_button]:hover:bg-[#3f3f46]  [&_button]:active:bg-[#71717a]',
);

export const bannerTitleStyles = cva('text-xl mb-2 ', {
  variants: {
    isEnterpriseBlog: {
      false: 'font-aktivgroteskextended',
      true: 'font-medium font-faktum text-[24px]',
    },
  },
});
