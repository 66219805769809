import {useEffect, useState} from 'react';

import {BLOG_ARTICLE_ID} from '@/components/blog/constants';
import type {
  Article,
  ArticleMetafield,
  ArticleAuthor,
  ContentNavigationLink,
  Settings,
} from '@/components/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {twMerge} from '@/stylesheets/twMerge';
import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import {articleStyles} from '@/components/blog/BlogArticleContent/style';
import {BlogAuthorAndSharingSection} from '@/components/pages/blog/BlogAuthor/BlogAuthorAndSharingSection';
import {BlogCitation} from '@/components/blog/BlogArticleContent/BlogCitation/BlogCitation';
import {getArticleTimeToRead} from '@/components/blog/utils';

import {BlogArticleNavigation} from './BlogArticleNavigation/BlogArticleNavigation';
import {BlogArticleRenderer} from './BlogArticleRenderer/BlogArticleRenderer';
import {ContentRightColumn} from './ContentRightColumn/ContentRightColumn';

interface BlogArticleContentProps {
  className?: string;
  content: string;
  title: string;
  authorAvatarUrl?: string;
  author?: ArticleAuthor;
  modifiedAt: string;
  popularPosts: Article[];
  enBlogPath: string;
  contentNavigationLinks: ContentNavigationLink[];
  settings: Settings;
  blogPageType: BLOG_PAGE_TYPE;
  articleHandle: string;
  metafields?: ArticleMetafield[];
}

export const BlogArticleContent = ({
  className,
  content,
  title,
  authorAvatarUrl,
  author,
  modifiedAt,
  popularPosts,
  enBlogPath,
  contentNavigationLinks,
  settings,
  blogPageType,
  metafields,
}: BlogArticleContentProps) => {
  const [contentHeight, setContentHeight] = useState<null | number>(null);
  const timeToRead = getArticleTimeToRead(content);

  const isEngineeringBlog = blogPageType === BLOG_PAGE_TYPE.ENGINEERING;

  useEffect(() => {
    const articleContentBox = document.getElementById(BLOG_ARTICLE_ID);
    const currentContentHeight = articleContentBox?.clientHeight;

    if (currentContentHeight && currentContentHeight !== contentHeight) {
      setContentHeight(currentContentHeight);
    }
  }, [content, contentHeight]);

  return (
    <Grid
      className={twMerge('block tablet-xl:grid gap-y-0 gap-x-12', className)}
    >
      <Col
        className="nav-drawer md:pb-20 pt-10 desktop:pt-16"
        span={{xs: 12, md: 4, lg: 3}}
        data-component-name="blog-left-sidebar"
      >
        <BlogArticleNavigation
          contentNavigationLinks={contentNavigationLinks}
          blogPageType={blogPageType}
          enBlogPath={enBlogPath}
        />
      </Col>
      <Col span={{xs: 12, md: 8, lg: 6}}>
        <div
          id={BLOG_ARTICLE_ID}
          className={articleStyles({blogType: blogPageType})}
          itemProp="articleBody"
        >
          <BlogArticleRenderer content={content} />
        </div>
        <BlogCitation />
        {(author || blogPageType === BLOG_PAGE_TYPE.ENTERPRISE) && (
          <BlogAuthorAndSharingSection
            author={author}
            authorAvatarUrl={authorAvatarUrl}
            modifiedAt={modifiedAt}
            articleTitle={title}
            blogPageType={blogPageType}
            enBlogPath={enBlogPath}
            metafields={metafields}
          />
        )}
      </Col>
      <Col
        span={{xs: 12, lg: 3}}
        className="hidden lg:block relative"
        data-component-name="blog-right-sidebar"
      >
        <div className="tablet-xl:pt-10 desktop:pt-16 pb-6 absolute h-full max-h-full tot-0 left-0">
          <ContentRightColumn
            popularPosts={popularPosts}
            contentHeight={contentHeight}
            enBlogPath={enBlogPath}
            author={author}
            modifiedAt={modifiedAt}
            settings={settings}
            blogPageType={blogPageType}
            metafields={metafields}
            timeToRead={isEngineeringBlog ? timeToRead : null}
          />
        </div>
      </Col>
    </Grid>
  );
};
