import {cva} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export const articleAuthorStyles = cva(
  ['font-bold text-body-sm text-legacy-gray-90'],
  {
    variants: {
      blogPageType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'text-body-sm text-legacy-gray-90 [&_>div]:!text-legacy-gray-90 [&_a]:text-legacy-gray-90 [&_span]:text-legacy-gray-90',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-text',
      },
    },
  },
);
