import Conversion from '@/components/pages/blog/SubscribeForm/Conversion/Conversion';
import Image from '@/components/base/elements/Image/Image';

import {BLOG_PAGE_TYPE} from '../types';

import {
  headingStyles,
  ctaStyles,
  SectionWrapperStyles,
  ImageStyles,
  ConverionWrapperStyles,
  ConversionStyles,
} from './style';

interface BlogConvertionCtaProps {
  text: string;
  emailPlaceholder: string;
}

interface BlogKeepUpSectionProps {
  heading: string;
  subhead: string;
  disclaimer: string;
  subscriptionId: string;
  imageSrc: string;
  conversionCta: BlogConvertionCtaProps;
  mode: 'default' | 'enterprise';
  blogType?: BLOG_PAGE_TYPE;
}

export default function BlogKeepUpSection({
  heading,
  subhead,
  disclaimer,
  conversionCta,
  subscriptionId,
  imageSrc,
  mode = 'default',
  blogType = BLOG_PAGE_TYPE.BLOG,
}: BlogKeepUpSectionProps) {
  return (
    <div className={SectionWrapperStyles({mode})}>
      <div className="relative overflow-hidden">
        <Image
          src={imageSrc}
          alt="subscription banner"
          className={ImageStyles({mode})}
        />
      </div>
      <div className={ConverionWrapperStyles({mode})}>
        <Conversion
          headingHtml={heading}
          subheadHtml={subhead}
          disclaimerHtml={disclaimer}
          buttons={[]}
          headingClassName={headingStyles({mode})}
          ctaClassName={ctaStyles}
          className={ConversionStyles({mode})}
          cta={conversionCta}
          mode="dark"
          center={false}
          subscriptionId={subscriptionId}
          blogType={blogType}
        />
      </div>
    </div>
  );
}
