import {cva, type VariantProps} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {twMerge} from '@/stylesheets/twMerge';

export const blogArticleHeroContainerStyles = cva(
  ['py-10 tablet:text-center desktop:pt-16'],
  {
    variants: {
      isImageVisible: {
        true: 'desktop:pb-16',
        false: 'pb-0 md:pb-4',
      },
    },
  },
);

const blogArticleContainerLinksVariants = cva(
  [
    'uppercase text-shade-100',
    'font-aktivgroteskextended text-[12px] leading-[14px] tracking-[0.01em] font-semibold',
    'tablet-xl:font-shopifysans tablet-xl:text-[11px] tablet-xl:leading-[13px] tablet:tracking-[0.5px] tablet:font-bold',
  ],
  {
    variants: {
      isImageVisible: {
        true: 'pb-2',
        false: 'pb-4',
      },
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-text',
      },
    },
  },
);

export const blogArticleContainerLinksStyles = (
  variants: VariantProps<typeof blogArticleContainerLinksVariants>,
) => twMerge(blogArticleContainerLinksVariants(variants));

export const blogArticleTitleStyles = cva(
  [
    'font-medium tracking-[-0.02em]',
    'text-[28px] leading-9',
    'tablet-xl:text-[32px] tablet-xl:leading-10',
    'tablet-xl:text-[40px] tablet-xl:leading-[48px]',
  ],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-text',
      },
    },
  },
);

export const blogArticleDescriptionStyles = cva(
  [
    'font-normal tracking-[-0.02em]',
    'text-[18px] leading-[26px]',
    'tablet-xl:text-[20px] tablet-xl:leading-7',
    'desktop:text-[22px] desktop:leading-8',
    'mt-4',
  ],
  {
    variants: {
      isImageVisible: {
        true: 'desktop:mt-4',
        false: 'desktop:mt-2',
      },
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: 'font-shopifysans',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-text',
      },
    },
  },
);

export const articleAuthorStyles = cva(
  ['font-bold sm:justify-center text-body-sm text-legacy-gray-90'],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'text-base leading-[18px] mb-2 [&_>div]:!text-legacy-gray-90 [&_a]:text-legacy-gray-90 [&_span]:text-legacy-gray-90',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-text',
      },
    },
  },
);

const blogTopicLinkVariants = cva(
  'no-underline hover:text-link-light-hover topic-link',
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'hover:text-inherit',
      },
    },
  },
);

export const blogTopicLinkStyles = (
  variants: VariantProps<typeof blogTopicLinkVariants>,
) => twMerge(blogTopicLinkVariants(variants));
