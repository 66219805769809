import type {SVGAttributes} from 'react';

const SvgSocialFacebook = (props: SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="37"
    viewBox="0 0 36 37"
    {...props}
  >
    <circle cx="18" cy="18.6143" r="18" fill="#E7ECFB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4664 25.3643V19.2063H14.2495V16.8064H16.4664V15.0366C16.4664 12.9826 17.8084 11.8643 19.7685 11.8643C20.7074 11.8643 21.5144 11.9296 21.7495 11.9588V14.1053L20.3901 14.1059C19.3241 14.1059 19.1177 14.5793 19.1177 15.2742V16.8064H21.66L21.329 19.2063H19.1177V25.3643H16.4664Z"
      fill="#120937"
    />
  </svg>
);

export default SvgSocialFacebook;
