import Typography from '@/components/base/elements/Typography/Typography';
import Button from '@/components/base/elements/Button/Button';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {PARTNERS_SIGNUP_URL} from '@/components/pages/partners/blog/constants';
import {EN_BLOG_PATH} from '@/utils/blog/partnersHelpers';

export const PartnersBlogSideCta = () => {
  const {t} = useSharedTranslations(`pages${EN_BLOG_PATH}$article/content`);

  return (
    <div className="bg-[#EAE8FF] hidden md:block">
      <div className="p-6">
        <Typography className="text-2xl font-aktivgroteskextended text-[#390278] font-bold mb-4">
          {t('articleNavigation.bannerTitle')}
        </Typography>
        <Typography className="font-aktivgroteskextended text-[#212326] text-base mb-4">
          {t('articleNavigation.bannerSubTitle')}
        </Typography>
        <Button
          href={PARTNERS_SIGNUP_URL}
          size="small"
          className="text-base bg-[#390278] hover:bg-[#390278] active:bg-[#390278] focus:bg-[#390278]"
        >
          {t('articleNavigation.buttonText')}
        </Button>
      </div>
    </div>
  );
};
