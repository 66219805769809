export default function ArrowRight({className}: {className?: string}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="currentColor"
    >
      <path
        d="M8.96729 1.11719L13.0005 5.00012L8.96729 8.88306"
        stroke="#F6F6F1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999512 5.00029H11.864"
        stroke="#F6F6F1"
        strokeLinecap="round"
      />
    </svg>
  );
}
