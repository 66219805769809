import {useLoaderData} from '@remix-run/react';
import get from 'lodash/get';

import {CitationAccordion} from '@/components/blog/BlogArticleContent/BlogCitation/CitationAccordion/CitationAccordion';
import {citationAccordionWrapper} from '@/components/blog/BlogArticleContent/style';

export const BlogCitation = () => {
  const {article, settings} = useLoaderData<any>() ?? {};
  const articleCitations = get(settings?.citation, article.handle, null);

  return (
    articleCitations && (
      <div className={citationAccordionWrapper}>
        <CitationAccordion items={articleCitations} />
      </div>
    )
  );
};
