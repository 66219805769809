import {cx, cva} from 'class-variance-authority';

export const headingStyles = cva('', {
  variants: {
    mode: {
      default: [
        '[&_span]:font-aktivgroteskextended',
        '[&_span]:text-left tablet-xl:[&_span]:text-[40px] [&_span]:leading-[48px] [&_span]:font-medium [&_span]:tracking-normal',
        '[&_p]:text-white [&_p]:text-left [&_p]:text-lg [&_p]:leading-[26px] [&_p]:font-normal [&_p]:tracking-tight [&_p]:pb-6',
      ],
      enterprise: [
        '[&_span]:font-factum',
        '[&_span]:text-left [&_span]:text-[28px] [&_p]:leading-[36px] desktop:[&_span]:text-[40px] [&_span]:leading-[48px] [&_span]:font-normal [&_span]:tracking-normal',
        '[&_p]:text-white [&_p]:text-left [&_p]:text-body-base [&_p]:leading-[26px]  desktop:[&_p]:text-[18px] [&_p]:font-normal [&_p]:tracking-tight [&_p]:pb-6',
      ],
    },
  },
});

export const ctaStyles = cx(
  '[&_p]:text-left [&_p]:text-xs [&_p]:leading-[18px] [&_p]:pt-xs [&_button]:mt-[3px]',
);

export const SectionWrapperStyles = cva('grid', {
  variants: {
    mode: {
      default: ['md:grid-cols-2'],
      enterprise: ['tablet:grid-cols-2'],
    },
  },
});

export const ImageStyles = cva('h-full w-full', {
  variants: {
    mode: {
      default: ['object-cover absolute top-0 left-0 bottom-0'],
      enterprise: [''],
    },
  },
});

export const ConverionWrapperStyles = cva('flex', {
  variants: {
    mode: {
      default: ['bg-black'],
      enterprise: [
        'bg-section-dark-bg items-center',
        '[&_form]:w-auto [&_p]:w-auto [&_form]:max-w-[406px] [&_p]:max-w-[406px]',
        '[&_button[type="submit"]]:btn-primary [&_button[type="submit"]]:mt-0 [&_form>div]:items-center',
        '[&_form>div+div]:hidden [&_form>div>input+div]:inline-block',
      ],
    },
  },
});

export const ConversionStyles = cva('text-left desktop:mx-10', {
  variants: {
    mode: {
      default: [
        'self-center py-16 md:py-28 mx-10 md:mx-0 md:px-4 desktop:px-8',
      ],
      enterprise: ['mx-0 py-8 px-4 tablet:py-10 tablet:px-8'],
    },
  },
});
