import type {ReactNode} from 'react';
import {useState} from 'react';
import cn from 'classnames';

import Toggle from '@/components/shared/Toggle/Toggle';

interface AccordionItemProps {
  buttonId: string;
  buttonText: ReactNode;
  buttonTag?: keyof JSX.IntrinsicElements;
  children: ReactNode;
  listItemAttributes?: any;
  listStyles?: any;
  toggleWrapperStyles?: string;
  noAnimation?: boolean;
  startOpen?: boolean;
  toggleIcons?: {
    expand: ReactNode;
    collapse: ReactNode;
  };
  toggleId: string;
  toggleWrapperClassName?: string;
  toggleClassName?: string;
}

export default function AccordionItem({
  buttonId,
  buttonText,
  buttonTag = 'h3',
  children,
  listItemAttributes,
  listStyles,
  toggleWrapperStyles,
  noAnimation = false,
  startOpen = true,
  toggleIcons,
  toggleId,
  toggleWrapperClassName,
  toggleClassName,
}: AccordionItemProps) {
  const [isActive, setIsActive] = useState(startOpen);
  let toggleIcon;
  if (toggleIcons) {
    toggleIcon = isActive ? toggleIcons.collapse : toggleIcons.expand;
  }

  const transitionAfter =
    'after:[transition:height_400ms_linear,max-height_0s_400ms_linear]';
  const transitionContent =
    '[&>*]:[transition:margin-bottom_400ms_cubic-bezier(1,0,1,1),visibility_0s_400ms,max-height_0s_400ms]';

  const animationClasses = cn(
    'after:content-[""] overflow-hidden',
    transitionAfter,
    transitionContent,
    {
      'after:h-0 after:max-h-[50px] [&>*]:max-h-0 [&>*]:invisible [&>*]:-mb-[2000px]':
        !isActive,
      'after:h-[50px] after:max-h-0 [&>*]:max-h-[10000px] [&>*]:[transition:margin-bottom_0.2s_cubic-bezier(0,0,0,1)]':
        isActive,
    },
  );

  const ButtonWrapper = buttonTag as any;

  return (
    <li
      className={cn(
        'mb-4 border-0 last:border-0 border-b border-solid border-legacy-gray-30 pb-2 leading-[1.6] border-shade-25 list-none py-xl first:pt-0',
        {'is-active': isActive},
        listStyles,
      )}
      data-component-name={toggleId
        .replace(/[A-Z]/g, (letter) => `-${letter.toLocaleLowerCase()}`)
        .replace(/[0-9]+/g, (digits) => `-${digits}`)
        .substring(1)}
      {...listItemAttributes}
    >
      <ButtonWrapper>
        <button
          aria-controls={toggleId}
          aria-expanded={isActive}
          className={cn(
            'flex w-full cursor-pointer items-center justify-between gap-2 border-0 p-0 after:w-4 after:h-4 after:ml-4 after:mb-4 flex-none',
            !toggleIcons && {
              'after:content-accordion-expand': !isActive,
              'after:content-accordion-collapse': isActive,
            },
            toggleWrapperStyles,
          )}
          data-component-name={`${buttonId}-${isActive ? 'hide' : 'show'}`}
          id={buttonId}
          onClick={() => setIsActive(!isActive)}
          type="button"
        >
          <div
            className={cn(
              'w-full text-left flex justify-between align-center',
              toggleWrapperClassName,
            )}
          >
            {buttonText}
            <span className={toggleClassName} aria-hidden="true">
              {toggleIcon}
            </span>
          </div>
        </button>
      </ButtonWrapper>
      <Toggle
        aria-labelledby={buttonId}
        className={noAnimation ? '' : animationClasses}
        hasAnimation={!noAnimation}
        isActive={isActive}
        role="region"
        toggleId={toggleId}
      >
        {children}
      </Toggle>
    </li>
  );
}
