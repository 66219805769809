import {twMerge} from '@/stylesheets/twMerge';
import Typography from '@/components/base/elements/Typography/Typography';
import {SideBannerCtaButton} from '@/components/blog/BlogArticleContent/BlogSideBanner/SideBannerCtaButton/SideBannerCtaButton';

interface BlogSideCtaProps {
  className?: string;
  title: string;
  subTitle: string;
  buttonText: string;
  href: string;
  target?: '_blank' | '_self';
}

export const BlogSideCta = ({
  className,
  title,
  subTitle,
  buttonText,
  href,
  target,
}: BlogSideCtaProps) => (
  <div className={twMerge('hidden md:block', className)}>
    <div className="p-6">
      <Typography className="text-2xl font-aktivgroteskextended leading-4 desktop:leading-7 color-black tracking-[-0.02em]">
        {title}
      </Typography>
      <Typography className="font-aktivgroteskextended leading-4 desktop:leading-7 color-black tracking-[-0.02em] text-2xl mb-9 font-bold">
        {subTitle}
      </Typography>
      <SideBannerCtaButton url={href} text={buttonText} target={target} />
    </div>
  </div>
);
