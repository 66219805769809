import type {Article, Settings} from '@/components/blog/types';
import type {Site} from '@data/types';

export const getArticleYear = (title: string, modifiedAt: string) => {
  const currentYear = new Date().getFullYear();
  if (
    !title.includes(currentYear.toString()) &&
    !title.includes((currentYear + 1).toString()) &&
    modifiedAt &&
    new Date(modifiedAt).getFullYear() >= currentYear - 1
  ) {
    return new Date(modifiedAt).getFullYear();
  }
};

export const getPopularPosts = (
  settings: Settings,
  site: Site,
  article: Article,
) => {
  const articleHandle = `blog/${article?.handle}`;

  return (
    settings.popularArticles?.byHandle?.[site.locale]?.[articleHandle] ||
    settings.popularArticles?.byHandle?.[site.languageCode]?.[articleHandle] ||
    settings.popularArticles?.[site.locale] ||
    settings.popularArticles?.[site.languageCode] ||
    []
  );
};
