import {useTranslations} from '@/hooks/useTranslations';
import {BlogSideCtaStyles} from '@/components/blog/BlogArticleContent/BlogArticleNavigation/style';
import {BlogSideCta} from '@/components/blog/BlogArticleContent/BlogSideBanner/BlogSideCta';
import {CAREERS_URL} from '@/pages/shopify.engineering/components/constants';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export const EngineeringBlogSideCta = () => {
  const {t} = useTranslations();

  return (
    <BlogSideCta
      className={BlogSideCtaStyles({
        mode: BLOG_PAGE_TYPE.ENGINEERING,
      })}
      title={t('articleNavigation.bannerTitle')}
      subTitle={t('articleNavigation.bannerSubTitle')}
      buttonText={t('articleNavigation.buttonText')}
      target="_blank"
      href={CAREERS_URL}
    />
  );
};
