import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import type {ShortcodeTransformer} from '@/components/blog/BlogArticleContent/BlogArticleRenderer/types';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

export const blogYoutubeTransformer: ShortcodeTransformer = {
  selector: '[data-component="youtube-facade"]',
  transformation: (node: Element) => {
    const embedUrl = new URL(
      node.getAttribute('data-src') || '',
      'https://www.youtube.com',
    );
    const videoId = embedUrl.pathname.split('/').pop();
    const params = embedUrl.searchParams.toString();

    const title = node.getAttribute('data-title');

    return {
      component: (
        <YoutubeFacade
          videoId={videoId ?? ''}
          title={title ?? undefined}
          params={params ?? undefined}
        />
      ),
      node,
    };
  },
};

function YoutubeFacade({
  videoId,
  params,
  title = 'YouTube Video',
}: {
  videoId: string;
  title?: string;
  params?: string;
}) {
  return (
    <LiteYouTubeEmbed
      id={videoId}
      adNetwork={false}
      params={params}
      title={title}
      poster="hqdefault"
    />
  );
}
