import type {FormEventHandler} from 'react';
import {useCallback, useState, useId, useRef} from 'react';
import {Form, useLoaderData} from '@remix-run/react';

import FormButton from '@/components/base/elements/FormButton/FormButton';
import Typography from '@/components/base/elements/Typography/Typography';
import {useTranslations, useSharedTranslations} from '@/hooks/useTranslations';
import {ModalSizeEnum} from '@/enums';
import Modal from '@/components/shared/Modal/Modal';
import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import useIntlLocale from '@/hooks/useIntlLocale';
import BaseInput from '@/components/shared/BaseInput/BaseInput';
import {determineArticlePath} from '@/components/blog/utils';
import type {ShortcodeTransformer} from '@/components/blog/BlogArticleContent/BlogArticleRenderer/types';

export const blogDataGuideTransformer: ShortcodeTransformer = {
  selector: '[data-guide]',
  transformation: (node: Element) => {
    const type = node.getAttribute('data-guide');
    const image = node.getAttribute('data-guide-image');

    return {
      component:
        type && image ? <BlogDataGuide type={type} image={image} /> : null,
      node,
    };
  },
};

function BlogDataGuide({type, image}: {type: string; image: string}) {
  const {blogHandle} = useLoaderData<{blogHandle: string}>();
  const articlePath = determineArticlePath(blogHandle);
  const {t, images} = useTranslations();
  const {t: sharedT} = useSharedTranslations(
    `pages${articlePath}/$article/guide_ctas`,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>('');
  const formRef = useRef<HTMLFormElement>(null);
  const fieldId = useId();

  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        // fire & forget
        await fetch(
          'https://marketing.preferences.shopify.com/public/subscriptions/ECCD4B57-A614-4C50-B027-80FC55965E43.json',
          {
            method: 'POST',
            body: new FormData(formRef.current || undefined),
          },
        );
        setSuccess(true);
      } catch (e) {
        setError(t('subscribe.guidesCta.error'));
      }
    },
    [t],
  );

  const locale = useIntlLocale();
  const language = locale.split('-')[0];

  return (
    <div className="flex font-aktivgroteskextended flex-col md:flex-row bg-[#F4F4EE] p-8">
      <div className="flex self-center md:w-32 shrink-0">
        <img
          alt="presentation"
          role="presentation"
          src={image}
          className="w-32 h-32"
        />
      </div>
      <div className="w-full md:pl-8">
        <Typography className="!mb-2 !mt-0 text-base md:text-t6 text-black !font-medium">
          {sharedT(`guidesCta.${type}.heading`)}
        </Typography>
        <Typography className="!mb-0 text-sm">
          {sharedT(`guidesCta.${type}.content`)}
        </Typography>
        <FormButton className="mt-6" onClick={() => setModalOpen(true)}>
          {sharedT(`guidesCta.${type}.cta`)}
        </FormButton>
      </div>

      <Modal
        closeLabel={t('global:ariaLabels.modal.close')}
        handleClose={handleClose}
        id="collective"
        isActive={modalOpen}
        modalSize={ModalSizeEnum.Full}
        aria-label={t('global:ariaLabels.videoModal')}
      >
        <Grid className="h-full bg-white rounded-lg text-black">
          <Col
            start={1}
            span={{
              xs: 12,
              md: 4,
            }}
          >
            <div className="flex justify-center items-center h-full">
              <img
                alt="presentation"
                className="p-6 md:p-0"
                role="presentation"
                src={
                  ['photography', 'seo'].includes(type)
                    ? images[`guides-modal-${type}`]
                    : images['guides-modal-default']
                }
              />
            </div>
          </Col>
          <Col
            start={{
              xs: 1,
              md: 5,
            }}
            span={{
              xs: 12,
              md: 7,
            }}
            className="p-6 md:py-10  justify-center place-items-center flex"
          >
            {success ? (
              <div>
                <Typography as="h3" className="text-xl mb-4">
                  {sharedT(`guidesCta.${type}.modal_success_headline`) ||
                    t(`subscribe.guidesCta.success.heading`)}
                </Typography>
                <Typography as="p" className="text-base">
                  {t(`subscribe.guidesCta.success.subhead`)}
                </Typography>
              </div>
            ) : (
              <div>
                <Typography as="h3" className="text-xl">
                  {sharedT(`guidesCta.${type}.modal_headline`)}
                </Typography>
                <Typography as="p" className="text-base">
                  {sharedT(`guidesCta.modal.subhead`)}
                </Typography>
                <Form
                  className="my-4 text-center"
                  onSubmit={handleSubmit}
                  ref={formRef}
                >
                  <input type="hidden" name="locale" value={language} />
                  <input type="hidden" name="content" value={type} />
                  <input type="hidden" name="signup_page" />
                  <div className="flex flex-col md:flex-row justify-center items-center md:justify-start [&>div:first-child]:w-full">
                    <BaseInput
                      type="email"
                      name="email"
                      autoComplete="email"
                      placeholder={t('global:signup.placeholder')}
                      id={`SubscribeEmail-${fieldId}`}
                      className="max-w-full w-full"
                      error={error}
                    />
                    <FormButton
                      className="max-w-full w-full md:w-40 mb-4 ml-0 md:ml-4 rounded-md h-[58px]"
                      type="submit"
                    >
                      {t(`subscribe.guidesCta.submit`)}
                    </FormButton>
                  </div>
                </Form>
                <Typography as="p" className="text-sm">
                  {sharedT(`guidesCta.modal.disclaimer`)}
                </Typography>
              </div>
            )}
          </Col>
        </Grid>
      </Modal>
    </div>
  );
}
