import {cva} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export const TopScrollBarStyles = cva('fixed top-0 left-0 h-[6px] z-50', {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: 'bg-blog-highlight',
      [BLOG_PAGE_TYPE.RETAIL]: 'bg-retail-highlight',
      [BLOG_PAGE_TYPE.PARTNERS]: 'bg-partners-highlight',
      [BLOG_PAGE_TYPE.ENTERPRISE]: 'bg-[#cdf986]',
      [BLOG_PAGE_TYPE.ENGINEERING]: 'bg-engineering-highlight',
    },
  },
  defaultVariants: {blogType: BLOG_PAGE_TYPE.BLOG},
});
