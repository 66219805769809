import {cva, type VariantProps} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {twMerge} from '@/stylesheets/twMerge';

const marketingCodeStyles = [
  '[&_.marketing-code]:bg-[#f0f1f2] [&_.marketing-code]:font-bold [&_.marketing-code]:p-1 [&_.marketing-code.marketing-code--block]:p-4',
  '[&_.marketing-code--block]:block [&_.marketing-code--block]:max-w-full [&_.marketing-code--block]:overflow-x-scroll [&_.marketing-code--block]:mb-5',
];

const truncatedTextStyles = [
  '[&_.truncated-text]:relative [&_.truncated-text]:max-h-[300px] [&_.truncated-text]:overflow-hidden [&_.truncated-text]:mb-6',

  '[&_.truncated-text::before]:absolute [&_.truncated-text::before]:content-[""] [&_.truncated-text::before]:bottom-0 [&_.truncated-text::before]:w-full [&_.truncated-text::before]:h-[150px]',
  '[&_.truncated-text::before]:bg-gradient-to-t [&_.truncated-text::before]:from-white [&_.truncated-text::before]:from-50% [&_.truncated-text::before]:to-transparent',

  // String.raw used for isolating underscore in classname,
  // more details: https://github.com/tailwindlabs/tailwindcss/issues/8881
  String.raw`[&_.truncated-text\_\_toggle]:absolute`,
  String.raw`[&_.truncated-text\_\_toggle]:bottom-0`,
  String.raw`[&_.truncated-text\_\_toggle]:w-full`,
  String.raw`[&_.truncated-text\_\_toggle]:text-center`,
];

const partnersSignupStyles = [
  '[&_.partners-signup]:mt-6 [&_.partners-signup]:mb-12 [&_.partners-signup]:bg-gray-200 [&_.partners-signup]:p-3 [&_.partners-signup]:md:p-6',
];

const tableStyles = [
  '[&_.table]:block [&_.table]:overflow-x-scroll [&_.table]:max-w-full',
];

const articleVariants = cva(
  [
    'text-body-base text-shade-70 md:pt-10 desktop:pt-16',
    '[&_h2]:text-t4 [&_h2]:mt-12 [&_h2]:mb-6 [&_h2]:text-black [&_h2]:font-medium',
    '[&_.heading--2]:text-t5 [&_.heading--2]:text-black [&_.heading--2]:font-aktivgroteskextended [&_.heading--2]:mb-6 [&_.heading--2]:font-medium',
    '[&_h3]:text-t5 [&_h3]:mt-12 [&_h3]:mb-4 [&_h3]:text-black',
    '[&_h4]:text-t6 [&_h4]:mt-12 [&_h4]:mb-4 [&_h4]:text-black',
    '[&_h5]:text-t7',
    '[&_p]:mb-6',
    '[&_hr]:mb-6',
    '[&_a]:text-black [&_a]:font-medium [&_a]:underline [&_a:hover]:no-underline',
    '[&_.marketing-button]:text-white [&_.marketing-button]:no-underline',
    '[&_a>img]:mt-3 [&_a>img]:mb-8 [&_a>img]:h-[revert-layer]',
    '[&_img]:my-12 [&_a>img]:inline-block [&_figure>img]:mb-0 [&_figure]:mb-12 [&_img]:inline-block',
    '[&_ul]:list-disc [&_ul]:space-y-3 [&_ul]:pl-6 [&_ul]:mb-6',
    '[&_ul_li]:pl-4',
    '[&_ol]:list-decimal [&_ol]:space-y-3 [&_ol]:pl-6 [&_ol]:mb-6',
    '[&_ol_li]:pl-4 [&_ol_li::marker]:font-bold',
    '[&_iframe]:max-w-full',
    '[&_figcaption]:mb-12 [&_figcaption]:text-center [&_figcaption]:text-xs',
    '[&_figcaption]:sm:text-sm [&_figcaption]:mt-1',
    '[&_strong]:font-medium [&_strong]:text-black',
    '[&_.heading--4]:font-aktivgroteskextended [&_.heading--4]:text-2xl [&_.heading--4]:text-black',
    '[&_.heading--4]:font-medium [&_.heading--4]:tracking-[-.02em]',
    '[&_iframe]:mt-3 [&_iframe]:mb-9',
    '[&_.container]:m-0 [&_.container]:flex [&_.container]:gap-5 [&_.container]:flex-wrap',
    '[&_.green]:text-[green] [&_.red]:text-[red]',
    '[&_.pros]:bg-[#eafaea] [&_.pros]:rounded-[10px] [&_.pros]:p-5 [&_.pros_h3]:mt-2',
    '[&_.cons]:bg-[#ffebe6] [&_.cons]:rounded-[10px] [&_.cons]:p-5 [&_.cons_h3]:mt-2',
    '[&_.pros]:tablet:flex-[1_0_calc(50%-10px)] [&_.pros]:flex-[1_0_100%]',
    '[&_.cons]:tablet:flex-[1_0_calc(50%-10px)] [&_.cons]:flex-[1_0_100%]',
    '[&_.pros_.grid-container]:grid [&_.cons_.grid-container]:grid [&_.grid-container]:gap-[5px]',
    '[&_.grid-container]:grid-cols-[15px_auto]',
    '[&_.pros_.grid-container]:p-[5px] [&_.cons_.grid-container]:p-[5px]',
    '[&_.aspect-video_iframe]:mt-0',
    'article-content',
    ...truncatedTextStyles,
    ...marketingCodeStyles,
    ...partnersSignupStyles,
    ...tableStyles,
  ],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '[&_ul>li>p]:inline',
        [BLOG_PAGE_TYPE.ENGINEERING]: [
          'text-engineering-dark-text',
          '[&_h2]:text-inherit [&_h3]:text-inherit [&_h4]:text-inherit [&_.heading--4]:text-inherit',
          '[&_a]:text-[#E7ECFB]',
          '[&_strong]:text-engineering-dark-text',
        ],
      },
    },
  },
);

export const articleStyles = (variants: VariantProps<typeof articleVariants>) =>
  twMerge(articleVariants(variants));

export const marketingBlockStyles = cva(
  [
    'my-12',
    'border-l-2 pl-4',
    '[&_h2]:!mt-0 [&_h2]:mb-6 [&_h2]:text-t5',
    '[&_h3]:!mt-0 [&_h3]:mb-6 [&_h3]:text-t7',
    '[&_h4]:!mt-0 [&_h4]:mb-6 [&_h4]:text-t8',
    '[&_ul]:!mt-0 [&_ul]:mb-6 [&_ul:last-child]:mb-0',
    '[&_ol]:!mt-0 [&_ol]:mb-6 [&_ol:last-child]:mb-0',
    '[&_p]:!mt-0 [&_p]:mb-6 [&_p:last-child]:mb-0',
  ],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: 'border-blog-highlight',
        [BLOG_PAGE_TYPE.RETAIL]: 'border-retail-highlight',
        [BLOG_PAGE_TYPE.PARTNERS]: 'border-partners-highlight',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'border-section-royal-blue !border-l-0 !font-faktum !font-normal [&_h3]:!font-normal bg-marketing-block !pl-8 [&_p]:!font-shopifysans [&_h3]:!font-faktum',
        [BLOG_PAGE_TYPE.ENGINEERING]:
          'text-engineering-dark-text [&_*]:!text-engineering-dark-text bg-transparent border-engineering-highlight',
      },
    },
    defaultVariants: {
      blogType: BLOG_PAGE_TYPE.BLOG,
    },
  },
);

export const marketingFaqBlockStyles = cva(
  [
    'text-body-sm my-8 tablet:my-16',
    'p-8 bg-marketingBg !border-l-0 border-t-2 pl-8',
    '[&_[itemscope]]:mb-6 [&_[itemscope]:last-child]:mb-0',
  ],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: 'border-blog-highlight',
        [BLOG_PAGE_TYPE.RETAIL]: 'border-retail-highlight',
        [BLOG_PAGE_TYPE.PARTNERS]: 'border-partners-highlight',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'border-section-royal-blue bg-marketing-block',
        [BLOG_PAGE_TYPE.ENGINEERING]:
          'text-engineering-dark-text !bg-[#0C052B] border-engineering-highlight',
      },
    },
    defaultVariants: {
      blogType: BLOG_PAGE_TYPE.BLOG,
    },
  },
);

export const inlineCtaStyles = cva(
  [
    'border-t-2',
    'px-8 py-10 my-12',
    '[&_h2]:!mt-0 [&_h2]:mb-6 [&_h2]:text-t5',
    '[&_h3]:!mt-0 [&_h3]:mb-6 [&_h3]:text-t7',
    '[&_h4]:!mt-0 [&_h4]:mb-6 [&_h4]:text-t8',
    '[&_img]:my-auto',
    '[&_p]:!mt-0 [&_p]:mb-6 [&_p]:text-body-sm',
    '[&_.heading--4]:text-t6',
    '[&_.marketing-button]:text-white [&_.marketing-button]:no-underline',
  ],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: 'border-blog-highlight',
        [BLOG_PAGE_TYPE.RETAIL]: 'border-retail-highlight',
        [BLOG_PAGE_TYPE.PARTNERS]: 'border-partners-highlight',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'border-section-royal-blue [&_h3]:!font-normal [&_.heading--4]:text-t7 !font-faktum [&_p]:!font-shopifysans [&_p]:!text-base [&_p]:!font-normal [&_a]:!font-shopifysans',
        [BLOG_PAGE_TYPE.ENGINEERING]: '!bg-[#0C052B] border-blog-highlight',
      },
    },
    defaultVariants: {
      blogType: BLOG_PAGE_TYPE.BLOG,
    },
  },
);

export const blockquoteStyles = cva(
  [
    'border-l-2 pl-4',
    'font-aktivgroteskextended text-xl font-medium',
    'tracking-tight my-12 [&_p]:mb-4',
    '[&_cite]:font-aktivgroteskextended [&_cite]:text-sm [&_cite]:not-italic',
    '[&_cite]:font-medium [&_cite]:tracking-[-0.0175rem] [&_footer]:mt-4',
    '[&_cite::before]:content-["–"] [&_cite::before]:mr-1',
  ],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: 'border-blog-highlight',
        [BLOG_PAGE_TYPE.RETAIL]: 'border-retail-highlight',
        [BLOG_PAGE_TYPE.PARTNERS]: 'border-partners-highlight',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'border-section-royal-blue [&_+p>i]:text-sm !font-faktum !bg-white [&_p:last-child]:text-sm [&_p:last-child]:font-medium !text-2xl',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'border-blog-highlight',
      },
    },
    defaultVariants: {
      blogType: BLOG_PAGE_TYPE.BLOG,
    },
  },
);

export const readMoreStyles = cva(
  [
    'marketing-block marketing-block--light marketing-block--padded text-body-sm text-left',
    'my-8 tablet:my-16 p-8 bg-marketingBg border-t-2',
    '[&_h2]:text-t5',
  ],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: 'border-blog-highlight',
        [BLOG_PAGE_TYPE.RETAIL]: 'border-retail-highlight',
        [BLOG_PAGE_TYPE.PARTNERS]: 'border-partners-highlight',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'border-section-royal-blue !font-faktum !bg-marketing-block !pl-8 [&_p]:!font-shopifysans [&_h3]:!font-faktum [&_h2]:!font-faktum',
        [BLOG_PAGE_TYPE.ENGINEERING]:
          'text-engineering-dark-text !bg-[#0C052B] border-engineering-highlight',
      },
    },
    defaultVariants: {
      blogType: BLOG_PAGE_TYPE.BLOG,
    },
  },
);

export const citationAccordionWrapper =
  'mb-12 p-8 border-t-2 bg-marketingBg border-blog-highlight';
