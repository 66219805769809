import {useEffect, useRef, useState} from 'react';

import {BLOG_ARTICLE_ID} from '@/components/blog/constants';
import {TopScrollBarStyles} from '@/components/blog/TopScrollbar/styles';
import type {BLOG_PAGE_TYPE} from '@/components/blog/types';

interface TopScrollbarProps {
  blogPageType: BLOG_PAGE_TYPE;
}

export const TopScrollbar = ({blogPageType}: TopScrollbarProps) => {
  const [scrollProgress, setScrollProgress] = useState<string | null>(null);
  let animationFrameId = useRef<number | null>(null);

  useEffect(() => {
    const handleScrollProgressRAF = () => {
      if (animationFrameId.current !== null) {
        // If an animation frame request already exists, cancel it
        window.cancelAnimationFrame(animationFrameId.current);
      }
      // Request the browser to call handleScrollProgress before the next repaint
      animationFrameId.current =
        window.requestAnimationFrame(handleScrollProgress);
    };

    window.addEventListener('scroll', handleScrollProgressRAF);
    window.addEventListener('resize', handleScrollProgressRAF);

    handleScrollProgress();

    return () => {
      window.removeEventListener('scroll', handleScrollProgressRAF);
      window.removeEventListener('resize', handleScrollProgressRAF);

      if (animationFrameId.current !== null) {
        // Cancel the animation frame request when the component unmounts
        window.cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  const handleScrollProgress = () => {
    const article = document.getElementById(BLOG_ARTICLE_ID);
    const offsetTop = window?.scrollY;
    const articleHeight = article?.scrollHeight || 1;

    setScrollProgress(((100 * offsetTop) / articleHeight).toFixed(2));
  };

  return (
    <div
      className={TopScrollBarStyles({blogType: blogPageType})}
      style={{width: `${scrollProgress}%`}}
    />
  );
};
