import type {SVGAttributes} from 'react';

const SvgSocialTwitter = (props: SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    {...props}
  >
    <circle cx="18" cy="18.6143" r="18" fill="#E7ECFB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3786 17.0672C23.3786 17.9589 23.2031 18.8473 22.851 19.7304C22.5 20.6135 22.0027 21.4212 21.3615 22.1535C20.7202 22.8858 19.8844 23.4781 18.8561 23.9326C17.8279 24.387 16.7074 24.6143 15.4946 24.6143C13.9657 24.6143 12.5505 24.219 11.25 23.4275C11.4784 23.4447 11.6977 23.4533 11.9081 23.4533C13.2097 23.4533 14.3606 23.0743 15.363 22.3172C14.7656 22.3172 14.2335 22.1449 13.7677 21.8003C13.302 21.4546 12.9814 21.013 12.8047 20.4746C12.9814 20.509 13.1659 20.5252 13.3594 20.5252C13.6575 20.5252 13.9219 20.5004 14.1502 20.4498C13.5349 20.3152 13.0376 20.0083 12.6596 19.5279C12.2827 19.0487 12.0937 18.4898 12.0937 17.8501V17.7995C12.0937 17.9007 12.2119 17.9804 12.4492 18.0396C12.6866 18.0978 12.9375 18.1279 13.2007 18.1279C12.7969 17.8749 12.4627 17.555 12.1984 17.1684C11.9351 16.7818 11.8035 16.3693 11.8035 15.931C11.8035 15.4604 11.9261 15.0146 12.1725 14.5935C12.8756 15.4184 13.7239 16.0743 14.7172 16.5621C15.7095 17.051 16.7692 17.3203 17.8942 17.3698C17.8414 17.1684 17.8144 16.966 17.8144 16.7646C17.8144 16.0409 18.0877 15.4184 18.6322 14.8972C19.1767 14.3749 19.8281 14.1143 20.583 14.1143C21.3919 14.1143 22.0691 14.3921 22.6136 14.9467C23.229 14.8293 23.8174 14.6193 24.3799 14.3156C24.1695 14.9392 23.7566 15.427 23.1412 15.7803C23.7037 15.7124 24.2392 15.5703 24.75 15.3506C24.363 15.889 23.8972 16.3521 23.3516 16.7387C23.3696 16.8572 23.3786 16.966 23.3786 17.0672Z"
      fill="#120937"
    />
  </svg>
);

export default SvgSocialTwitter;
