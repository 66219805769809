import * as uuid from 'uuid';
import {useMemo} from 'react';

import {useTranslations} from '@/hooks/useTranslations';
import Accordion from '@/components/shared/Accordion/Accordion';
import Icon from '@/components/base/elements/Icon/Icon';
import {IconEnum} from '@/enums';

interface CitationAccordionProps {
  items: {
    text: string;
    url: string;
  }[];
}

export const CitationAccordion = ({items}: CitationAccordionProps) => {
  const {t} = useTranslations();

  const content = useMemo(() => {
    return (
      <ul className="ml-6 mt-6 text-body-sm list-disc">
        {items.map(({url, text}) => (
          <li key={uuid.v4()} className="mt-3 first:mt-0 pl-4">
            <a
              href={url}
              className="underline hover:no-underline font-medium group/link"
              itemProp="citation"
              target="_blank"
              rel="noreferrer"
            >
              {text}
              <Icon
                icon={IconEnum.DiagonalArrow}
                size={14}
                className="inline-block ml-1 group-hover/link:rotate-45 transition-transform duration-300 ease-out-cubic pb-0.5"
              />
            </a>
          </li>
        ))}
      </ul>
    );
  }, [items]);

  return (
    <Accordion
      id="citation-accordion"
      className=""
      listStyles="!mb-0 !pb-0"
      accordionItems={[
        {
          buttonText: <h2 className="text-t5">{t('citation.header')}</h2>,
          body: content,
        },
      ]}
      startOpen={false}
      noAnimation
    />
  );
};
