import {cva} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {classNames} from '@/pages/shopify.engineering/styles/styles';

export const popularArticlesStyles = cva(
  'overflow-hidden lg:hidden tablet-xl:pt-0 pt-0 [&_h5]:uppercase',
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '[&_.popular-posts]:border-t-2',
        [BLOG_PAGE_TYPE.RETAIL]: '[&_.popular-posts]:border-t-2',
        [BLOG_PAGE_TYPE.PARTNERS]: '[&_.popular-posts]:border-t-2',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'bg-white [&_h3]:uppercase [&_h3]:font-faktum [&_h3]:!font-normal [&_h3]:!text-sm [&_article]:border-t [&_article]:border-shade-30',
        [BLOG_PAGE_TYPE.ENGINEERING]: [
          classNames.title,
          '[&_.blogPost>div:first-child>a:not(:hover)]:text-engineering-highlight',
        ],
      },
    },
  },
);

export const articleSectionStyles = cva('featured-blogs gap-y-0 pt-36 pb-0', {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: 'bg-white',
      [BLOG_PAGE_TYPE.ENGINEERING]: 'bg-transparent',
    },
  },
});
