import Typography from '@/components/base/elements/Typography/Typography';
import type {BLOG_PAGE_TYPE, Article} from '@/components/blog/types';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import {twMerge} from '@/stylesheets/twMerge';
import {topicEnPath, filterArticleTag} from '@/components/pages/blog/utils';

import {
  articleItemStyles,
  articleTagStyles,
  articleTitleStyles,
  titleStyles,
  wrapperStyles,
} from './styles';

interface ArticleListProps {
  className?: string;
  storefrontArticles: Article[];
  enBlogPath: string;
  blogPageType: BLOG_PAGE_TYPE;
}

export const PopularPostsBanner = ({
  className,
  storefrontArticles,
  enBlogPath,
  blogPageType,
}: ArticleListProps) => {
  const {t} = useSharedTranslations(`pages${enBlogPath}$article/content`);
  const {localizePath} = useTranslations();

  return (
    <div
      className={twMerge(wrapperStyles({blogType: blogPageType}), className)}
      data-priority="0"
    >
      <Typography className={titleStyles({blogType: blogPageType})}>
        {t('popularPostsTitle')}
      </Typography>
      {storefrontArticles &&
        storefrontArticles.map((article: Article) => {
          const articleTag = filterArticleTag(article.tags);

          return (
            <span
              className={articleItemStyles({blogType: blogPageType})}
              key={article.handle}
            >
              <span className={articleTagStyles({blogType: blogPageType})}>
                {articleTag && (
                  <a
                    href={localizePath(topicEnPath(enBlogPath, articleTag))}
                    data-component-name={`blog-popular-post-${articleTag}`}
                  >
                    {articleTag}
                  </a>
                )}
              </span>
              <a
                href={localizePath(enBlogPath + article.handle)}
                data-component-name={`blog-popular-post-${article.handle}`}
              >
                <Typography
                  as="span"
                  className={articleTitleStyles({blogType: blogPageType})}
                >
                  {article.title}
                </Typography>
              </a>
            </span>
          );
        })}
    </div>
  );
};
