import {cva, type VariantProps} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {twMerge} from '@/stylesheets/twMerge';

const wrapperVariants = cva('rounded-full bg-marketingBg mb-6 px-4 py-2.5', {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: '',
      [BLOG_PAGE_TYPE.ENGINEERING]: '[&_a]:text-engineering-light-text',
    },
  },
});

export const wrapperStyles = (variants: VariantProps<typeof wrapperVariants>) =>
  twMerge(wrapperVariants(variants));

const linkVariants = cva(
  'flex justify-between align-middle font-aktivgroteskextended text-sm leading-6 tracking-[-0.0175rem] !no-underline group hover:text-link-light-hover blog-cta-link',
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'hover:text-engineering-light-text',
      },
    },
  },
);

export const linkStyles = (variants: VariantProps<typeof linkVariants>) =>
  twMerge(linkVariants(variants));
