import {useLocation} from '@remix-run/react';
import {useId} from 'react';

import type {I18NOptions, VariantMap} from '@/types';
import {useTranslations} from '@/hooks/useTranslations';
import {socialSharerUrls} from '@/components/blog/utils';
import Anchor from '@/components/shared/Anchor/Anchor';
import socialIcons from '@/components/shared/SocialIcons';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import SvgSocialFacebook from '@/pages/shopify.engineering/components/icons/Facebook';
import SvgSocialLinkedin from '@/pages/shopify.engineering/components/icons/LinkedIn';
import SvgSocialTwitter from '@/pages/shopify.engineering/components/icons/Twitter';

const socialIconsEngineering: VariantMap = {
  ...socialIcons,
  facebook: SvgSocialFacebook,
  linkedin: SvgSocialLinkedin,
  twitter: SvgSocialTwitter,
};

export interface BlogSocialLink {
  name: string;
  text: string;
}

interface BlogSocialIconsProps {
  listClass?: string;
  itemClass?: string;
  linkClass?: string;
  iconClass?: string;
  articleTitle: string;
  articleSummary?: string;
  socialLinksFn: (options: I18NOptions) => BlogSocialLink[];
  blogPageType?: BLOG_PAGE_TYPE;
}

export default function BlogSocialIcons({
  listClass,
  itemClass,
  linkClass,
  iconClass,
  articleTitle,
  articleSummary,
  socialLinksFn,
  blogPageType,
}: BlogSocialIconsProps) {
  const translations = useTranslations();
  const socialLinks = socialLinksFn(translations);
  const location = useLocation();
  const uniqueId = useId();

  return (
    <ul id={uniqueId} className={listClass} data-component-name="social">
      {socialLinks.map(({name, text}) => {
        const Component =
          blogPageType !== BLOG_PAGE_TYPE.ENGINEERING
            ? socialIcons[name]
            : socialIconsEngineering[name];

        return (
          <li key={name} className={itemClass}>
            <Anchor
              className={linkClass}
              aria-describedby={`blog-social-icon-${name}-${uniqueId}`}
              href={socialSharerUrls(
                location.pathname,
                name,
                articleTitle,
                articleSummary,
              )}
              rel="me nofollow noopener noreferrer"
              target="_blank"
              data-component-name={`social-${name}`}
            >
              <Component className={iconClass} />
              <span
                id={`blog-social-icon-${name}-${uniqueId}`}
                className="sr-only"
              >
                {text}
              </span>
            </Anchor>
          </li>
        );
      })}
    </ul>
  );
}
