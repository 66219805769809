import {cva} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export const articleNavigation = cva(
  [
    'md:top-[calc(var(--header-height)*2)] md:max-h-[calc(100vh-var(--header-height))]',
    'md:overflow-auto md:sticky md:pb-6 pr-2',
  ],
  {
    variants: {
      blogType: {
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-text',
      },
    },
  },
);

export const mobileNav = cva(
  [
    'flex py-5 md:hidden border-y border-shade-20',
    'text-[12px] mb-6 md:mb-0 leading-[20px] font-medium',
  ],
  {
    variants: {
      isEnterpriseBlog: {
        true: 'uppercase',
        false: 'px-6',
      },
    },
  },
);

export const articleNavigationList = cva(
  [
    'relative overflow-auto',
    'before:absolute before:inset-y-0 before:left-0 md:before:bg-shade-20',
    'before:w-[1px] before:z-1 my-10 md:mt-0 md:mb-16',
  ],
  {
    variants: {
      isMobileNavOpen: {
        true: 'block',
        false: 'hidden md:block',
      },
      blogPageType: {
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]:
          'md:before:bg-engineering-light-author-text',
      },
    },
  },
);

export const BlogSideCtaStyles = cva('hidden md:block', {
  variants: {
    mode: {
      [BLOG_PAGE_TYPE.RETAIL]: 'bg-retail-bannerBg',
      [BLOG_PAGE_TYPE.BLOG]: 'bg-blog-bannerBg',
      [BLOG_PAGE_TYPE.PARTNERS]: 'bg-[#eae8ff]',
      [BLOG_PAGE_TYPE.ENTERPRISE]:
        'bg-button-dark-primary-bg [&_a]:font-faktum [&_p]:font-faktum [&_p]:leading-7',
      [BLOG_PAGE_TYPE.ENGINEERING]:
        'bg-engineering-dark-accent text-engineering-light-text',
    },
  },
});

export const articleNavigationItem = cva(
  ['pl-2 my-2.5 first:mt-0 last:mb-0 text-body-sm relative z-2'],
  {
    variants: {
      isActive: {true: 'pl-[7px] border-l border-l-black font-bold'},
      blogPageType: {
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'border-l-engineering-highlight',
      },
    },
  },
);
