import {useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {ChevronDownIcon} from '@shopify/polaris-icons';
import {useLoaderData} from '@remix-run/react';

import {BlogSideCta} from '@/components/blog/BlogArticleContent/BlogSideBanner/BlogSideCta';
import useSignup from '@/hooks/useSignup';
import useGlobalNav from '@/hooks/navigation/global';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import {PartnersBlogSideCta} from '@/components/pages/partners/blog/PartnersBlogSideCta/PartnersBlogSideCta';
import {EngineeringBlogSideCta} from '@/pages/shopify.engineering/$article/components/EngineeringBlogSideCta/EngineeringBlogSideCta';
import {useTailoredMessagingTopThreeCTAs} from '@/hooks/experiments/useTailoredMessagingTopThreeCTAs';
import {getPageCluster} from '@/components/blog/utils';

import {BLOG_PAGE_TYPE} from '../../types';
import type {ContentNavigationLink} from '../../types';

import {
  articleNavigation,
  articleNavigationItem,
  articleNavigationList,
  mobileNav,
  BlogSideCtaStyles,
} from './style';

interface BlogArticleNavigationProps {
  contentNavigationLinks: ContentNavigationLink[];
  blogPageType: BLOG_PAGE_TYPE;
  enBlogPath: string;
}

export const BlogArticleNavigation = ({
  contentNavigationLinks,
  blogPageType,
  enBlogPath,
}: BlogArticleNavigationProps) => {
  const activeSectionOffset = 150;
  const scrollTarget = useRef<HTMLElement | null>(null);
  const scrollToPos = useRef<number | null>(null);
  const [offsetsArray, setOffsetsArray] = useState<number[]>([]);
  const {t} = useSharedTranslations(`pages${enBlogPath}$article/content`);
  const {localizePath} = useTranslations();
  const {signupText} = useGlobalNav();
  const {signupUrl} = useSignup();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const {t: tCTAs} = useSharedTranslations(`pages/blog/$article/ctas`);
  const data = useLoaderData<any>() ?? {};

  const isEnterpriseBlog = blogPageType === BLOG_PAGE_TYPE.ENTERPRISE;

  const controlOffsets = useCallback(() => {
    let offsetsArr: number[] = [];

    contentNavigationLinks?.forEach((link) => {
      const {targetOffsetTop} = getTargetElementAndOffset(link.href);
      targetOffsetTop && offsetsArr.push(targetOffsetTop);
    });

    setOffsetsArray(offsetsArr);
  }, [contentNavigationLinks]);

  const scrollToTarget = useCallback((href: string) => {
    const {targetOffsetTop, targetElement} = getTargetElementAndOffset(href);
    location.hash = href;

    targetElement &&
      window.scrollTo({
        top: targetOffsetTop - activeSectionOffset,
        behavior: 'instant',
      });

    scrollTarget.current = targetElement;
    scrollToPos.current = targetOffsetTop;
  }, []);

  const checkScrollPosition = useCallback(() => {
    const currentOffset = window.scrollY;
    const lastIndex = offsetsArray.length - 1;

    if (currentOffset < offsetsArray[0]) {
      setActiveIndex(0);
    } else if (currentOffset > offsetsArray[lastIndex]) {
      setActiveIndex(lastIndex);
    } else {
      for (let i = 0; i <= offsetsArray.length; i++) {
        if (currentOffset + activeSectionOffset >= offsetsArray[i]) {
          setActiveIndex(i);
        } else {
          break;
        }
      }
    }

    const targetTopOffset = scrollTarget.current?.offsetTop;

    if (targetTopOffset && targetTopOffset !== scrollToPos.current) {
      scrollToPos.current = targetTopOffset;
      window.requestAnimationFrame(() =>
        window.scrollTo({
          top: targetTopOffset - activeSectionOffset,
          behavior: 'instant',
        }),
      );
    }

    if (scrollToPos.current && window.scrollY >= scrollToPos.current) {
      scrollToPos.current = null;
      scrollTarget.current = null;
    }
  }, [offsetsArray]);

  const onWindowResize = useCallback(() => {
    checkScrollPosition();
    controlOffsets();
  }, [checkScrollPosition, controlOffsets]);

  useEffect(() => {
    scrollToTarget(location.hash);
  }, [scrollToTarget]);

  useEffect(() => {
    controlOffsets();
  }, [activeIndex, controlOffsets]);

  useEffect(() => {
    checkScrollPosition();
    window.addEventListener('scroll', checkScrollPosition);
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
      window.removeEventListener('resize', onWindowResize);
    };
  }, [checkScrollPosition, offsetsArray, onWindowResize]);

  const getTargetElementAndOffset = (href: string) => {
    const targetElement = document?.getElementById(
      href.replace('#', ''),
    ) as HTMLElement;

    return {
      targetElement,
      targetOffsetTop: targetElement?.offsetTop,
    };
  };

  const hasTailoredMessaging = useTailoredMessagingTopThreeCTAs();
  const tailoredCTAMessages =
    data.pageCluster &&
    hasTailoredMessaging &&
    getPageCluster(
      tCTAs(`articleNavigation.${hasTailoredMessaging}.clusters`),
      data.pageCluster,
    );

  const {bannerTitle, bannerSubTitle} =
    tailoredCTAMessages || t('articleNavigation');

  const getSideCta = () => {
    switch (blogPageType) {
      case BLOG_PAGE_TYPE.PARTNERS:
        return <PartnersBlogSideCta />;
      case BLOG_PAGE_TYPE.ENGINEERING:
        return <EngineeringBlogSideCta />;
      default:
        return (
          <BlogSideCta
            className={BlogSideCtaStyles({
              mode: blogPageType,
            })}
            title={bannerTitle}
            subTitle={bannerSubTitle}
            buttonText={
              isEnterpriseBlog
                ? t('articleNavigation.bannerTextLink')
                : t(`articleNavigation.text`) || signupText
            }
            href={
              t(`articleNavigation.href`)
                ? localizePath(t(`articleNavigation.href`))
                : signupUrl
            }
          />
        );
    }
  };

  return (
    <nav className={articleNavigation({blogType: blogPageType})}>
      {contentNavigationLinks && contentNavigationLinks.length !== 0 && (
        <>
          <div
            className={mobileNav({isEnterpriseBlog})}
            onClick={() => {
              setIsMobileNavOpen((prevState) => {
                return !prevState;
              });
            }}
          >
            {t('articleNavigation.title')}
            <ChevronDownIcon
              className={cn('w-[20px] h-[20px] ml-auto transition', {
                '-rotate-180': isMobileNavOpen,
              })}
            />
          </div>
          <div className="mb-4 uppercase hidden md:block">
            {t('articleNavigation.title')}
          </div>
          <ul
            className={articleNavigationList({
              isMobileNavOpen,
              blogPageType,
            })}
          >
            {contentNavigationLinks?.map((navLink, index) => (
              <li
                key={`content-nav-item-${index}`}
                className={articleNavigationItem({
                  isActive: index === activeIndex,
                  blogPageType,
                })}
              >
                <a
                  href={navLink.href}
                  onClick={(event) => {
                    event.preventDefault();

                    const linkHref = navLink.href;

                    if (linkHref) {
                      scrollToTarget(linkHref);
                    }
                  }}
                >
                  {navLink.text}
                </a>
              </li>
            ))}
          </ul>
        </>
      )}
      {getSideCta()}
    </nav>
  );
};
