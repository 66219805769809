import {useTranslations} from '@/hooks/useTranslations';
import SubscribeForm from '@/components/pages/blog/SubscribeForm/SubscribeForm';
import {
  formWrapperStyles,
  inputWrapperStyles,
} from '@/components/pages/blog/components/CTA/styles';
import type {ShortcodeTransformer} from '@/components/blog/BlogArticleContent/BlogArticleRenderer/types';

export const blogDataSubscribeTransformer: ShortcodeTransformer = {
  selector: '[data-subscribe]',
  transformation: (node: Element) => {
    return {
      component: <BlogDataSubscribe />,
      node,
    };
  },
};

function BlogDataSubscribe() {
  const {t} = useTranslations();

  return (
    <div className="mb-12">
      <SubscribeForm
        mode="dark"
        buttonText={t('subscribe.guidesCta.submit')}
        placeholder={t('global:signup.placeholder')}
        label={t('global:signup.label')}
        successMessage={{
          heading: t('subscribe.guidesCta.success.heading'),
          contentHtml: t('subscribe.guidesCta.success.subhead'),
        }}
        className={formWrapperStyles()}
        inputWrapperClass={inputWrapperStyles()}
      />
    </div>
  );
}
