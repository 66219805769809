import type {SVGAttributes} from 'react';

const SvgSocialLinkedin = (props: SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    {...props}
  >
    <circle cx="18" cy="18.6143" r="18" fill="#E7ECFB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4102 12.6143C14.1884 12.6143 14.8191 13.2463 14.8191 14.0236C14.8191 14.8018 14.1884 15.4334 13.4102 15.4334C12.6293 15.4334 11.9995 14.8018 11.9995 14.0236C11.9995 13.2463 12.6293 12.6143 13.4102 12.6143ZM16.1521 16.5032H18.4826V17.572H18.5163C18.8405 16.9568 19.6337 16.3083 20.8163 16.3083C23.2766 16.3083 23.7315 17.9281 23.7315 20.035V24.3258H21.3026V20.5218C21.3026 19.6147 21.2849 18.4476 20.039 18.4476C18.7736 18.4476 18.5805 19.4353 18.5805 20.4562V24.3258H16.1521V16.5032ZM12.1929 24.326H14.6268V16.5034H12.1929V24.326Z"
      fill="#120937"
    />
  </svg>
);

export default SvgSocialLinkedin;
