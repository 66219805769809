import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import Typography from '@/components/base/elements/Typography/Typography';
import Button from '@/components/base/elements/Button/Button';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {SideBannerCtaButton} from '@/components/blog/BlogArticleContent/BlogSideBanner/SideBannerCtaButton/SideBannerCtaButton';

interface IBlogSideBannerProps {
  className?: string;
  imageWidthPadding?: boolean;
  imageSrc: string;
  imageAlt: string;
  title: string;
  buttonText: string;
  buttonHint: string;
  href: string;
  blogPageType: BLOG_PAGE_TYPE;
  titleStyle?: string;
  сomponentName?: string;
}

export const BlogSideBanner = ({
  className,
  imageWidthPadding,
  imageSrc,
  imageAlt,
  title,
  buttonText,
  buttonHint,
  href,
  titleStyle,
  blogPageType,
  сomponentName,
}: IBlogSideBannerProps) => {
  const ctaButton =
    blogPageType === BLOG_PAGE_TYPE.RETAIL ? (
      <SideBannerCtaButton url={href} text={buttonHint} />
    ) : (
      <>
        <Button href={href} size="small" className="text-base">
          {buttonText}
        </Button>
        <Typography className="text-[0.625rem] text-black pt-2">
          {buttonHint}
        </Typography>
      </>
    );

  return (
    <div
      className={className}
      data-priority="1"
      data-component-name={сomponentName}
    >
      <div className={cn(imageWidthPadding && 'p-4 pb-0')}>
        <img src={imageSrc} alt={imageAlt} />
      </div>
      <div className="p-4">
        <Typography
          className={twMerge(
            'color-black tracking-[-0.02em] text-2xl font-aktivgroteskextended',
            titleStyle,
          )}
        >
          {title}
        </Typography>
        {ctaButton}
      </div>
    </div>
  );
};
