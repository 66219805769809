import type {LinkComponent} from '@/types';
import ChevronRight from '@/components/blog/icons/ChevronRight';

export const SideBannerCtaButton = ({text, url, target}: LinkComponent) => (
  <a
    href={url}
    className="flex justify-between align-middle font-aktivgroteskextended leading-6 tracking-[-0.0175rem] !no-underline border-b-[1px] border-black group"
    target={target}
    {...(target === '_blank' ? {rel: 'noopener noreferrer'} : {})}
  >
    {text}
    <span className="flex justify-center items-center w-6 h-6 ml-4 my-auto [&_svg]:w-6 reduced-motion:group-hover:translate-x-0 transition-all duration-500 will-change-transform group-hover:translate-x-full group-hover:opacity-0">
      <ChevronRight />
    </span>
  </a>
);
